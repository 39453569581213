import React from 'react';
import loadable from '@loadable/component';
import { getFeatureFlags } from 'utils/feature-flags';
import { useCampaignModalUrlControl } from './hooks/useCampaignModalUrlControl';
import { CHRISTMAS_MODALS_APPROVED_PAGES, EASTER_MODALS_APPROVED_PAGES } from './definitions';

const EasterModals = loadable(() => import('./Modals/EasterModals'));
const ChristmasModals = loadable(() => import('./Modals/ChristmasModals'));

const flagMap: Record<string, string[]> = {
  slots_christmasEarlyAccessSlotsModalEnabled: CHRISTMAS_MODALS_APPROVED_PAGES,
  slots_christmasGeneralAccessSlotsModalEnabled: CHRISTMAS_MODALS_APPROVED_PAGES,
  slots_christmasEntertainingSlotsModalEnabled: CHRISTMAS_MODALS_APPROVED_PAGES,
  slots_christmasCollectionSlotsModalEnabled: CHRISTMAS_MODALS_APPROVED_PAGES,
  slots_christmasSellThroughSlotsModalEnabled: CHRISTMAS_MODALS_APPROVED_PAGES,
  slots_easterModalEnabled: EASTER_MODALS_APPROVED_PAGES,
};

const CampaignModals: React.FC = () => {
  const flags = getFeatureFlags();
  const isUrlApproved = useCampaignModalUrlControl();

  const christmasFlag = Object.keys(flags)
    .filter(key => key.indexOf('slots_christmas') === 0)
    .find(key => flags[key] === true);
  const easterFlags = Object.keys(flags)
    .filter(key => key.indexOf('slots_easter') === 0)
    .find(key => flags[key] === true);

  if (christmasFlag && isUrlApproved(flagMap[christmasFlag])) {
    return <ChristmasModals />;
  }

  if (easterFlags && isUrlApproved(flagMap[easterFlags])) {
    return <EasterModals />;
  }

  return null;
};

export default CampaignModals;
