import { withBlockingApis } from 'components/withBlockingApis';
import { hasCustomerSlotInitialLoadCompleted } from 'redux/modules/customer-slot/selectors';
import hasTrolleyLoadCompleted from 'redux/modules/trolley/selectors/get-loaded';
import CampaignModals from './CampaignModals';

export default withBlockingApis(
  CampaignModals,
  [hasCustomerSlotInitialLoadCompleted, hasTrolleyLoadCompleted],
  null,
);
