import urls from 'constants/urls';

export const CHRISTMAS_MODALS_APPROVED_PAGES = [
  urls.browse,
  urls.christmasHomePage,
  urls.entertainingChristmas,
  urls.entertainingHome,
  urls.favourites,
  urls.groceriesChristmas,
  urls.groceriesHome,
  urls.homepage,
  urls.lists,
  urls.multiSearch,
  urls.offerDetails,
  urls.offers,
  urls.search,
  urls.shopFromPrevious,
];

export const EASTER_MODALS_APPROVED_PAGES = [
  urls.browse,
  urls.favourites,
  urls.homepage,
  urls.groceriesHome,
];
